<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="prizeRecordList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="获奖用户ID" prop="user_id" align="center"></el-table-column>
        <el-table-column label="类型" prop="type" align="center"></el-table-column>
        <el-table-column label="奖励名称" prop="title" align="center"></el-table-column>
        <el-table-column label="奖品缩略图" align="center">
          <template slot-scope="scope">
            <div
              class="thumb_box"
              v-if="scope.row.thumb"
            >
              <img :src="scope.row.thumb_url" alt="" />
            </div>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" width="150" align="center">
          <template slot-scope="scope">
            {{$moment(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
          </template>
        </el-table-column>
       
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

    </el-card>
  </div>
</template>

<script>
import { PrizeRecord } from "@/api/winning_record.js";

export default {
  data() {
    return {
      queryForm: {
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      total: 0,
      times: null,
      prizeRecordList: [],
    };
  },
  created() {
    this.getPrizeRecord();
  },
  methods: {
    //   获取任务列表
    getPrizeRecord() {
      PrizeRecord(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.prizeRecordList = res.data.data;
      });
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getPrizeRecord();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getPrizeRecord();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getPrizeRecord();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}

.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 90px;
    height: 90px;
  }
}
</style>
