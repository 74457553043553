import request from "@/utils/request";

// 获取中奖记录列表
export function PrizeRecord(params) {
    return request({
        url: '/admin/base/prizeRecord',
        method: 'get',
        params
    }
    )
}